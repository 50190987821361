<template>
  <div class="home">
    <div class="home-block home-block-fixed">
      <div class="home-block-content">
        <p>{{ $t('home.hello') }}</p>
        <h1 class="home-block-content-name">CORENTIN DUMORTIER</h1>
        <v-typical
          class="home-block-content-jobs"
          :steps="[$t('home.job-assistant'), 400, $t('home.job-technician'), 400]"
          :loop="Infinity"
          :wrapper="'h2'"
          :key="key" />
      </div>
    </div>
    <div class="home-block home-block-fixed">
      <div class="home-block-content">
        <p>{{ $t('home.desired-job-intro') }}</p>
        <h2 class="home-block-content-desired-job">{{ $t('home.desired-job') }}</h2>
      </div>
    </div>
    <home-block title="navbar.about" @click="goTo('/about')" />
    <home-block title="navbar.contact" @click="goTo('/contact')" />
  </div>
</template>

<script>
  import { ref, watch } from 'vue'
  import { useRouter } from 'vue-router'

  import VTypical from 'vue-typical'
  import { useI18n } from 'vue-i18n'

  import HomeBlock from '@/components/HomeBlock'

  export default {
    name: 'Home',
    components: { HomeBlock, VTypical },
    setup() {
      let key = ref(0)
      let locale = ref(useI18n({ useScope: 'global' }).locale)
      watch(locale, () => key.value += 1)

      const router = useRouter()
      const goTo = link => {
        router.push(link)
      }

      return {key, goTo}
    }
  }
</script>

<style lang="scss" scoped>
  .home {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    flex-wrap: wrap;
    padding: 1rem;
    box-sizing: border-box;

    &-block {
      width: 48%;
      height: 48%;
      margin: 0.5rem 0;
      box-sizing: border-box;
      background-color: var(--second-color);
      color: var(--contrast-color);
      position: relative;

      &.home-block-fixed {
        background-color: var(--main-color);
      }

      * { text-transform: uppercase; 
      }

      &-content {
        @extend %position-abs;
        text-align: center;
        width: 100%;

        &-name { font-size: 2rem; }

        &-jobs {
          color: var(--main-color-accent);
          height: 2rem;
        }

        &-desired-job {
          color: var(--main-color-accent);
          font-size: 1.5rem;
        }
      }
    }
  }

  @include desktop {
    .home {
      flex-direction: column;

      &-block {
        width: 100%;
        height: 20rem;

        &-content {
          position: relative;
          padding: 2rem 0 4rem 0;
          transform: none;
          top: 0;
          left: 0;
          box-sizing: border-box;

          p { 
            font-size: 2rem;
            margin: 0;
          }

          &-name, &-jobs, &-desired-job {
            font-size: 3rem;
            margin: 1rem 0;
          }
        }
      }
    }
  }

  @include mobile {
    .home {
      &-block {
        &-content {
          &-name, &-jobs, &-desired-job { font-size: 1.8rem; }
        }
      }
    }
  }

  @include sm-mobile {
    .home {
      &-block {
        &-content {
          p { font-size: 1.5rem; }
          &-name, &-jobs, &-desired-job { font-size: 2rem; }
        }
      }
    }
  }
</style>
