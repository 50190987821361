<template>
  <div class="home-block">
    <h2 class="home-block-title">
      <span class="home-block-title-left" :data-hover="$t(title).split(' ')[0]">
        {{ $t(title).split(' ')[0] }}
      </span>
      <span class="home-block-title-right" :data-hover="$t(title).split(' ')[1]">
        {{ $t(title).substr($t(title).indexOf(' ')) }}
      </span>
    </h2>
  </div>
</template> 

<script>
  export default {
    name: 'HomeBlock',
    props: {
      title: {
        type: String,
        required: true
      }
    }
  }
</script>

<style lang="scss" scoped>
  .home-block {
    position: relative;
    cursor: pointer;

    * { text-transform: uppercase; }

    &-title {
      @extend %position-abs;
      margin: 0;
      overflow: hidden;
      font-size: 2.5rem;
      width: 100%;
      text-align: center;

      span {
        display: inline-block;
        position: relative;
        transition: transform 0.3s;

        &:before {
          position: absolute;
          content: attr(data-hover);
        }
      }

      &-left {
        color: var(--contrast-color);

        &:before {
          color: var(--main-color-accent);
          top: 105%;
        }
      }
      &-right {
        color: var(--main-color-accent);
        margin-left: 0.5rem;

        &:before {
          color: var(--contrast-color);
          bottom: 105%;
        }
      }
    }

    &:hover .home-block-title {
      &-left { transform: translate3d(0, -105%, 0); }
      &-right { transform: translate3d(0, 105%, 0); }
    }
  }
  
  @include desktop {
    .home-block {
      &-title {
        font-size: 3.5rem;
      }
    }
  }

  @include mobile {
    .home-block {
      &-title { font-size: 2.5rem; }
    }
  }

  @include sm-mobile {
    .home-block {
      &-title { font-size: 1.5rem; }
    }
  }
</style>